.App {
  text-align: center;
}

a, a:visited, a:focus {
  color: #146B3A;
}

h1, p {
  color: #fff;
}

h1 {
  font-size: 48px;
  font-family: 'Lobster Two', cursive;
}

h2 {
  font-size: 36px;
  font-family: 'Lobster Two', cursive;
}

img {
  width: 80vw;
}

section {
  padding: 32px 16px;
}

.green1 {
  background-color: #165B33;
}

.green2 {
  background-color: #146B3A;
}

.yellow {
  background-color: #F8B229;
}

.orange {
  background-color: #EA4630;
}

.red {
  background-color: #BB2528;
}
